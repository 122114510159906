/* ./src/styles/tailwind.css */
@tailwind base;

@tailwind components;

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(48, 48, 48, 0.75);
}
.ModalLanding {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background-color: #fdfdfd;
  border-radius: 0.5rem;
  opacity: 1;
}

.bg-shape-join {
  background-image: url("../assets/Auth/bg-shape-join.svg");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 110%;
}

.modal {
  z-index: 100;
  position: fixed;
  top: 11vh;
  left: 10%;
  width: 85%;
  min-height: 630;
}

@media (min-width: 1422px) {
  .sdHeight {
    height: 449px;
  }
  .backgroundSD {
    height: 160px;
  }

  .modal {
    z-index: 100;
    position: fixed;
    top: 6vh;
    left: 10%;
    width: 85%;
    height: 645px;
  }
}

@media (min-width: 1024px) and (max-width: 1421px) {
  .sdHeight {
    height: 449px;
  }
  .backgroundSD {
    height: 129px;
  }

  .modal {
    z-index: 100;
    position: fixed;
    top: 6vh;
    left: 10%;
    width: 85%;
    height: 645px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .modal {
    z-index: 100;
    position: fixed;
    top: 11vh;
    left: 10%;
    width: 85%;
    height: 400px;
  }
}

@media (min-width: 380px) and (max-width: 768px) {
  .backgroundSD {
    height: 129px;
  }
}

.textWhite p {
  color: white;
}

.hoverbackground:hover p {
  background-color: #5222d0 !important;
  color: #ffffff !important;
}

.wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: 4;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(12deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(9deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
body,
html {
  height: 100%;
}

body {
  font-family: "Roboto", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.spacing {
  border-spacing: 0 10px;
}

.backgroundSD {
  background-image: url("../assets/Table/shapes/greenshape.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.blueBG {
  background-image: url("../assets/Table/shapes/similar-blue-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.simplebar-content-wrapper {
  border-radius: 20px;
}

@import url("https://fonts.googleapis.com/css2?family=Baloo+Paaji+2:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap");
@tailwind utilities;
